
import React, {Fragment, forwardRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import '../../App.css'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/core/styles';
import constants from '../constantes'
import NavigationAdministrador from '../Navigation/NavigationAdministrador';
import NavigationRepresentante from '../Navigation/NavigationRepresentante';
import RepresentanteMedico from './Representante-Medico';
import Administrador from './Administrador';




// llamada a iconos de material ui de la datatable
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


// Estilos Modal

 const useStyles = makeStyles((theme) =>({
    modal: {
      position: 'absolute',
      width: '40%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2,4,3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    },
    inputMaterial:{
      width: '100%'
    }

})) 


// Encabezados table
const ValidacionAlta = () => {

    // Muestra los registros de vendedores en la base
    const [cotizaciones, setCotizaciones] = useState([])
    
    const [userdata, setUserData] = useState([])
    useEffect(async ()=> {
      let token = localStorage.getItem('token')
      let utype = localStorage.getItem('utype')

         const getcotizaciones = async () =>{
           await fetch(constants.api +'cotizaciones')
             .then(res => res.json())
             .then(res => setCotizaciones(res))
           
         }
  
         getcotizaciones()
    }, [])


  const styles = useStyles()

console.log(localStorage.getItem('utype'));
switch (localStorage.getItem('utype')) {
  case '0':
    return(
      
      <Fragment>      
    <NavigationAdministrador></NavigationAdministrador>
    <Administrador></Administrador>

   </Fragment> 
    
    
    );
    break;

    case '1':
    return(
      
      <Fragment>      
    <NavigationRepresentante></NavigationRepresentante>
    <RepresentanteMedico></RepresentanteMedico>
   </Fragment> 
    
    
    )


    break;

  default:
    
    break;
}









    
  }

  export default ValidacionAlta;