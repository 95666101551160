import logo from '../images/astrazeneca-logo-blanco.png';
import fondo from '../images/fondo-aztra.jpg'
import logocolor from '../images/astrazeneca-logo.png'



export default {
 "logo": logo,
 "fondo": fondo,
 "logocolor": logocolor
}