import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import constants from "../constantes";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import Administrador from "./ValidacionVistas";
import Login from "../Login";
import Loading from "../Loading"




// Encabezados table
const Principal = () => {
    // Muestra los registros de vendedores en la base
    const [cotizaciones, setCotizaciones] = useState([])
    const [isLogged, setisLogged] = useState("")
    let loged2 = true
    useEffect(async ()=> {
    await fetch(constants.api+'login/session')
        .then(res => res.json())
        .then(res => {
          console.log(res);   
          if(res){
            setisLogged(res.isLogged)
          }else{
          
          } 
        })
    }, [])
console.log(isLogged)

switch (isLogged) {
  case true:
    return(
      <NavigationAdministrador></NavigationAdministrador>
    )
    break;
    case false:
      return(
        <NavigationAdministrador></NavigationAdministrador>
      )
      //let redirect = () => {
        //window.location.href = constants.api+ '/login'
      //}
      //redirect()
    break;
  default:    
  return(      
      <Loading></Loading>     
  )
  break;
}









    
  }

  export default Principal;



