import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import constants from "../constantes";
import estilosForm from "../Formularios/FormulariosStyle";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { format } from 'date-fns';
import constantes from "../constantes";



import NavigationAdministrador from "../Navigation/NavigationAdministrador";

const ExportCatalogo = () => {
  const styles = estilosForm();

    useEffect( ()=> {
      
    
  
    }, []) 



  const [hinicio, sethInicio] = useState(new Date());;
  const [hfinal, sethFinal] = useState(new Date());;

  const handleChange = (e) =>{
  
    //console.log(e)
    sethInicio(e)
  

  }
  

  const handleChangeF = (e) =>{
  
    //console.log(e.target.value)
    sethFinal(e)
  

  }

  /* let horaiformat = hinicio.toLocaleDateString()
  let horafformat = hfinal.toLocaleDateString() */

  let fechai = format(hinicio, 'yyyy-MM-dd')
  let fechaf = format(hfinal, 'yyyy-MM-dd')

  let exportar = constants.api + "clues/az/getlayout/"+ fechai + "/" + fechaf

  console.log(exportar)


  return (
    <Fragment>
      <NavigationAdministrador></NavigationAdministrador>
      <div className="container ">
        <div className="row  justify-content-center" style={{ marginTop: "50px" }}>
          <div className="col-lg-12">
            <div className={styles.titulo}>ELIGE UN RANGO DE FECHA <br></br> PARA EXPORTAR EL CATÁLOGO <br></br> </div>
          </div>

          <div className="col-lg-4"><br></br><br></br>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                 mask="__/__/____"
                label="Fecha Inicio"
                inputFormat="dd/MM/yyyy"
                value={hinicio}
                fullwidth
                onChange={handleChange}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
              </LocalizationProvider>
          </div>

          <div className="col-lg-4"><br></br><br></br>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                 mask="__/__/____"
                label="Fecha Final"
                inputFormat="dd/MM/yyyy"
                value={hfinal}
                fullwidth
                onChange={handleChangeF}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
              </LocalizationProvider>
          </div>

          <div className="col-lg-4"><br></br><br></br>

          {}
          <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "50px;" }}
              startIcon={<SimCardDownloadIcon  />}
              
            >
             <a href={exportar}  style={{ color: "white", padding: "10px 0px" }}>
                Exportar base de datos
              </a>
            </Button>
            </div>
  

        </div>
      </div>
    </Fragment>
  );
};

export default ExportCatalogo;
