import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { makeStyles } from "@material-ui/core/styles";
import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import { TextField, Button } from "@material-ui/core";
import "../../css/custom.css";
import constants from "../constantes";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import estilosForm from "../Formularios/FormulariosStyle";
import NumberFormat from "react-number-format";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getFormControlUnstyledUtilityClasses } from "@mui/material";
import { reach } from "yup";
import FormImpl from "react-bootstrap/esm/Form";
import SendIcon from "@mui/icons-material/Send";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";

const re = /^[0-9\b]+$/;

const RegistroAdministrativo = () => {
  const MySwal = withReactContent(Swal);
  const styles = estilosForm();

  const validationSchema = yup.object({
    PostalCode: yup.number().required("El codigo postal es requerido"),
     //Speciality: yup.string().required("La especialidad es requerida"),
     LastName: yup.string().required("Los apellidos son requeridos"),
     FirstName: yup.string().required("El nombre es requerido"),
     CustomerName: yup.string().required("El nombre del hospital es requerido"),
     //Estado: yup.string().required("El nombre del estado es requerido"),
     CustomerType: yup.string().required("El tipo de institución es requerido"),
     //City: yup.string().required("La ciudad es requerida"),
     //District: yup.string().required("La colonia es requerida"),
     Province: yup.string().required("El municipio es requerido"),
     Region: yup.string().required("El estado es requerido"),
     AddressLine1: yup.string().required("La calle y numero son requeridos"),
     Gender: yup.string().required("El genero es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      IdentityValue: "",
      FirstName: "",
      LastName: "",
      HCA: "",
      CustomerName: "",
      CustomerType: "",
      PostalCode: "",
      City: "",
      District: "",
      Province: "",
      Region: "",
      AddressLine1: "",
      AddressLine2: "",
      Gender: "",
      Speciality: "",
      Speciality_id: "",
      hca_mdm_id: "",
      Profession: "",
      CustomerUser: 1,
      isNew: false,
      Estado: "",
    },
    onSubmit: (values) => {
      if (formik.values.isNew) {
        formik.values.CustomerName = "HOSPITAL " + namehospitalnuevo + " SMP";
      }

      console.log("si entra aqui");
      let consulta = constants.api + "clues/reltio/hcp";

      const requestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      fetch(consulta, requestInit)
        .then((res) => {
          if (res.status == 200) {
            let redirect;
            MySwal.fire({
              title: (
                <p>
                  El administrativo se ha insertado correctamente en la base de
                  datos
                </p>
              ),
              icon: "success",
              confirmButtonText: "Cerrar",
            }).then(
              (redirect = () => {
                window.location.href = "/registro-administrativo";
              }),

              setTimeout(redirect, 2000),
              setFormulario(false)
            );
            console.log("No se encontro cedula");
          } else {
            MySwal.fire({
              title: <p>Ah ocurrido un error intentalo de nuevo</p>,
              icon: "error",
              confirmButtonText: "Cerrar",
            }).then(setFormulario(false));
          }

          return res.json();
        })
        .then((res) => {
          console.log(res);
        });

      console.log(JSON.stringify(values));
    },

    validationSchema: validationSchema,
  });

  const [formulario, setFormulario] = useState(false);
  const [data, setData] = useState([]);
  const [inputhospital, setInputHospital] = useState(false);
  const [consultorio, setinputConsultorio] = useState(false);
  const [inputtipolugar, setinputtipoLugar] = useState(false);
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isDisabledcedula, setIsDisabledCedula] = useState(false);
  const [isDisabledconsultotorio, setIsDisabledConsultorio] = useState(false);
  const [isDisabledhospitaldata, setIsDisabledHospitalData] = useState(false);
  const [texthospital, setTextHospital] = useState("");
  const [suggestionshosp, setSuggestionsHosp] = useState([]);
  const [textspeciality, setTextSpeciality] = useState("");
  const [suggestionsspeciality, setSuggestionsSpeciality] = useState([]);
  const [datoscontacto, setDatosContacto] = useState(false);
  const [nuevohosp, setDatosNuevoHosp] = useState(false);
  const [estado, setEstado] = useState("");
  const [sector, setSector] = useState("");
  const [namehospitalnuevo, setNameHospitalNuevo] = useState("");

  const [abrirform, setAbrirForm] = useState(false);
  const [nombrehospita, setNombreHospita] = useState("");

  const handleChangeHospitalType = (event) => {
    let hospitaltype = event.target.value;
    formik.values.CustomerType = hospitaltype;
    if (hospitaltype !== 0) {
      console.log("Escogiste un privado ");
      setinputConsultorio(true);
      setInputHospital(false);
      setDatosContacto(true);
      setIsDisabledConsultorio(true);
      setIsDisabledHospitalData(false);
      setDatosNuevoHosp(false);

      setNombreHospita(false); //quito nombre hospital
      formik.values.isNew = true

      formik.values.CustomerName =
        "CONSULTORIO PRIVADO" +
        " " +
        formik.values.FirstName +
        " " +
        formik.values.LastName;

      formik.values.Region = "";
      formik.values.Province = "";
      formik.values.District = "";
      formik.values.PostalCode = "";
    } else {
      console.log("Escogiste una institución");
      setinputConsultorio(false);
      setInputHospital(true);
      setDatosContacto(true);
      setIsDisabledConsultorio(true);
      setIsDisabledHospitalData(true);
      setNombreHospita(false); //quito nombre hospital
      formik.values.CustomerName = "";
      formik.values.Region = "";
      formik.values.Province = "";
      formik.values.District = "";
      formik.values.PostalCode = "";
    }
  };

  const onChangeHandlerhosp2 = (hospital) => {
    let hospitalv = hospital.toUpperCase();
    setNameHospitalNuevo(hospitalv);

    formik.values.CustomerName = hospitalv;
  };

  const onSuggestHandler = (obj) => {
    setText(obj.CP);
    formik.values.PostalCode = obj.CP;
    formik.values.Region = obj.ESTADO;
    formik.values.Province = obj.MUNICIPIO.toUpperCase();
    formik.values.District = obj.ASENTAMIENTO.toUpperCase();
    //Formik.values.Region = obj.

    setSuggestions([]);
  };

  const onChangeHandler = async (text) => {
    setText(text);
    formik.values.PostalCode = text;

    let consulta = constants.api + "zip_code/" + text;

    if (text.length >= 5) {
      const getCP = async () => {
        await fetch(consulta)
          .then((res) => res.json())
          .then((res) => setSuggestions(res));
      };
      await getCP();
    } else {
      setSuggestions([]);
    }

    console.log(suggestions);
  };

  // HANDLER HOSPITAL O AGREGAR HOSPITAL

  const onSuggestHandlerHosp = (obj) => {
    if (!obj.postal_code == "") {
      formik.values.CustomerName = obj.hca_name;
      formik.values.PostalCode = obj.postal_code;
      formik.values.Region = obj.region;
      formik.values.Province = obj.province;
      formik.values.District = obj.district;
      formik.values.hca_mdm_id = obj.hca_mdm_id;
      formik.values.AddressLine1 = obj.address_line_one;
      formik.values.isNew = false;
      setIsDisabledHospitalData(true);
      setDatosContacto(true);
      setDatosNuevoHosp(false);
      console.log("hospital en catalogo");
    } else {
      formik.values.CustomerName = "";
      setIsDisabledHospitalData(false);
      setDatosNuevoHosp(true);
      setInputHospital(false);
      setDatosContacto(false);
      formik.values.isNew = true;

      console.log("hospital en catalogo");
    }
    setSuggestionsHosp([]);
  };
  const onChangeHandlerHosp = async (text) => {
    setTextHospital(text);

    formik.values.CustomerName = text;
    let consulta =
      constants.api + "clues/get/" + formik.values.Estado + "/" + text;
    //let consulta = constants.api + "clues/get/" + text;

    if (text.length > 3) {
      const getHosp = async () => {
        await fetch(consulta)
          .then((res) => res.json())
          .then((res) => {
            setSuggestionsHosp(res);
          });
      };
      await getHosp();
    } else {
      setSuggestionsHosp([]);
    }

    console.log(suggestionshosp);
  };

  // HANDLER ESPECIALIDAD PREDICTIVE

  // HANDLER HOSPITAL

  const handleChangeEstado = (estado) => {
    let estadov = estado.target.value;
    setEstado(estadov);
  };

  const handleSector = (sector) => {
    let sectorv = sector.target.value;
    setSector(sectorv);

    if (sectorv !== 0) {
      console.log("Sector privado ");
      setinputConsultorio(false);
      setInputHospital(false);
      setDatosContacto(true);

      setIsDisabledHospitalData(false);
      setNombreHospita(true);

      formik.values.CustomerName = "";

      formik.values.Region = "";
      formik.values.Province = "";
      formik.values.District = "";
      formik.values.PostalCode = "";
    } else {
      console.log("Sector publico");
      setinputConsultorio(false);
      setInputHospital(false);
      setDatosContacto(true);
      setNombreHospita(true);

      formik.values.CustomerName = "";

      setIsDisabledHospitalData(false);

      formik.values.Region = "";
      formik.values.Province = "";
      formik.values.District = "";
      formik.values.PostalCode = "";
    }
  };

  const handleChangeName = (event) => {
    setText(event.value);
    //console.log(event.id)

    switch (event.id) {
      case "FirstName":
        formik.values.FirstName = event.value.toUpperCase();
        console.log(formik.values.FirstName);
        break;

      case "LastName":
        formik.values.LastName = event.value.toUpperCase();
        console.log(formik.values.LastName.toUpperCase());
        break;

      default:
        break;
    }

    let consulta =
      constants.api +
      "clues/reltio/hcp/name/" +
      formik.values.FirstName +
      "/" +
      formik.values.LastName;

    if (
      formik.values.FirstName.length >= 4 &&
      formik.values.LastName.length >= 4
    ) {
      fetch(consulta)
        .then((res) => res.json())
        .then((res) => {
          if (res.messages) {
            console.log("ese nombre no existe registro abrir registro");

            setAbrirForm(true);
          } else {
            MySwal.fire({
              title: <p>El administrativo ya esta registrado en reltio</p>,
              icon: "warning",
              confirmButtonText: "Cerrar",
            }).then(setFormulario(false));
            console.log("ese nombre si existe existe registro");
            console.log(res);
            setAbrirForm(false);
          }
        });
    } else {
      setSuggestionsSpeciality([]);
    }
  };

  return (
    <Fragment>
      <NavigationAdministrador></NavigationAdministrador>
      <div className="container ">
        <div className="row  justify-content-center">
          <div className="col-lg-10">
            <br></br>
            <br></br>
            <div className={styles.titulo}>REGISTRO ADMINISTRATIVO </div>
            <br></br>

            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <TextField
                    id="FirstName"
                    name="FirstName"
                    label="NOMBRES"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                    value={formik.values.FirstName}
                    onChange={(e) => handleChangeName(e.target)}
                    error={
                      formik.touched.FirstName &&
                      Boolean(formik.errors.FirstName)
                    }
                    helperText={
                      formik.touched.FirstName && formik.errors.FirstName
                    }
                    onBlur={formik.handleBlur}
                  />

                  {/*  */}
                </div>

                <div className="col-lg-6">
                  <TextField
                    id="LastName"
                    name="LastName"
                    label="APELLIDOS"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formik.values.LastName}
                    autoComplete="off"
                    onChange={(e) => handleChangeName(e.target)}
                    error={
                      formik.touched.LastName && Boolean(formik.errors.LastName)
                    }
                    helperText={
                      formik.touched.LastName && formik.errors.LastName
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="row">
                {/*  INPUT NOMBRE HOSPITAL */}
                <div className="col-lg-12">
                  {abrirform && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="demo-simple-select-helper-label">
                        SELECCIONA TIPO DE INSTITUCIÓN
                      </InputLabel>
                      <Select
                        id="CustomerType"
                        name="CustomerType"
                        label="SELECCIONA TIPO DE INSTITUCIÓN"
                        value={formik.values.CustomerType}
                        onChange={(e) => handleChangeHospitalType(e)}
                        error={
                          formik.touched.CustomerType &&
                          Boolean(formik.errors.CustomerType)
                        }
                        helperText={
                          formik.touched.CustomerType &&
                          formik.errors.CustomerType
                        }
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value={0}>INSTITUCIÓN HOSPITALARIA</MenuItem>
                        <MenuItem value={1}>CONSULTORIO PRIVADO</MenuItem>
                      </Select>
                      <FormHelperText>
                        {formik.touched.CustomerType &&
                          formik.errors.CustomerType}
                      </FormHelperText>
                    </FormControl>
                  )}

                  {inputhospital && (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-helper-label">
                          SELECCIONA ESTADO
                        </InputLabel>
                        <Select
                          id="Estado"
                          name="Estado"
                          label="SELECCIONA ESTADO"
                          value={formik.values.Estado}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.Estado &&
                            Boolean(formik.errors.Estado)
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={"AGUASCALIENTES"}>
                            AGUASCALIENTES
                          </MenuItem>
                          <MenuItem value={"BAJA CALIFORNIA"}>
                            BAJA CALIFORNIA
                          </MenuItem>
                          <MenuItem value={"BAJA CALIFORNIA SUR"}>
                            BAJA CALIFORNIA SUR{" "}
                          </MenuItem>
                          <MenuItem value={"CAMPECHE"}>CAMPECHE </MenuItem>
                          <MenuItem value={"CHIAPAS"}>CHIAPAS </MenuItem>
                          <MenuItem value={"CHIHUAHUA"}>CHIHUAHUA </MenuItem>
                          <MenuItem value={"CIUDAD DE MEXICO"}>
                            CIUDAD DE MEXICO{" "}
                          </MenuItem>
                          <MenuItem value={"COAHUILA"}>COAHUILA </MenuItem>
                          <MenuItem value={"COLIMA"}>COLIMA </MenuItem>
                          <MenuItem value={"DURANGO"}>DURANGO </MenuItem>
                          <MenuItem value={"GUANAJUATO"}>GUANAJUATO </MenuItem>
                          <MenuItem value={"GUERRERO"}>GUERRERO </MenuItem>
                          <MenuItem value={"HIDALGO"}>HIDALGO </MenuItem>
                          <MenuItem value={"JALISCO"}>JALISCO </MenuItem>
                          <MenuItem value={"MEXICO"}>MEXICO </MenuItem>
                          <MenuItem value={"MICHOACAN"}>MICHOACAN </MenuItem>
                          <MenuItem value={"MORELOS"}>MORELOS </MenuItem>
                          <MenuItem value={"NAYARIT"}>NAYARIT </MenuItem>
                          <MenuItem value={"NUEVO LEON"}>NUEVO LEON </MenuItem>
                          <MenuItem value={"OAXACA"}>OAXACA </MenuItem>
                          <MenuItem value={"PUEBLA"}>PUEBLA </MenuItem>
                          <MenuItem value={"QUERETARO"}>QUERETARO </MenuItem>
                          <MenuItem value={"QUINTANA ROO"}>
                            QUINTANA ROO{" "}
                          </MenuItem>
                          <MenuItem value={"SAN LUIS POTOSI"}>
                            SAN LUIS POTOSI{" "}
                          </MenuItem>
                          <MenuItem value={"SINALOA"}>SINALOA </MenuItem>
                          <MenuItem value={"SONORA"}>SONORA </MenuItem>
                          <MenuItem value={"TABASCO"}>TABASCO </MenuItem>
                          <MenuItem value={"TAMAULIPAS"}>TAMAULIPAS </MenuItem>
                          <MenuItem value={"TLAXCALA"}>TLAXCALA </MenuItem>
                          <MenuItem value={"VERACRUZ"}>VERACRUZ </MenuItem>
                          <MenuItem value={"YUCATAN"}>YUCATAN </MenuItem>
                          <MenuItem value={"ZACATECAS"}>ZACATECAS </MenuItem>
                        </Select>
                        <FormHelperText>
                          {formik.touched.Estado && formik.errors.Estado}
                        </FormHelperText>
                      </FormControl>
                      <TextField
                        id="CustomerName"
                        name="CustomerName"
                        label="NOMBRE HOSPITAL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.CustomerName}
                        autoComplete="new-password"
                        onChange={(e) => onChangeHandlerHosp(e.target.value)}
                        error={
                          formik.touched.CustomerName &&
                          Boolean(formik.errors.CustomerName)
                        }
                        helperText={
                          formik.touched.CustomerName &&
                          formik.errors.CustomerName
                        }
                        onBlur={formik.handleBlur}
                      />
                    </>
                  )}

                  <ul className="suggestions-list">
                    {suggestionshosp &&
                      suggestionshosp.map((suggestion, i) => (
                        <li
                          className="col-lg-12 justify-content-md-center suggestion "
                          onClick={() => onSuggestHandlerHosp(suggestion)}
                          key={i}
                        >
                          {}{" "}
                          {suggestion.hca_name +
                            " " +
                            suggestion.postal_code +
                            " " +
                            suggestion.city +
                            " " +
                            suggestion.province}
                        </li>
                      ))}
                  </ul>

                  {nuevohosp && (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-helper-label">
                          SECTOR
                        </InputLabel>
                        <Select
                          id="SECTOR"
                          name="SECTOR"
                          label="SECTOR"
                          value={sector}
                          onChange={(e) => handleSector(e)}
                        >
                          <MenuItem value={0}>PÚBLICO</MenuItem>
                          <MenuItem value={1}>PRIVADO</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}

                  {nombrehospita && (
                    <TextField
                      id="CustomerName"
                      name="CustomerName"
                      label="NOMBRE HOSPITAL"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formik.values.CustomerName}
                      autoComplete="new-password"
                      onChange={(e) => onChangeHandlerhosp2(e.target.value)}
                      error={
                        formik.touched.CustomerName &&
                        Boolean(formik.errors.CustomerName)
                      }
                      helperText={
                        formik.touched.CustomerName &&
                        formik.errors.CustomerName
                      }
                      onBlur={formik.handleBlur}
                    />
                  )}

                  {/*  INPUT TIPO DE LUGAR*/}
                  {consultorio && (
                    <TextField
                      id="CustomerName"
                      name="CustomerName"
                      label="NOMBRE CONSULTORIO"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formik.values.CustomerName}
                      autoComplete="new-password"
                      disabled={isDisabledconsultotorio}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.CustomerName &&
                        Boolean(formik.errors.CustomerName)
                      }
                      helperText={
                        formik.touched.CustomerName &&
                        formik.errors.CustomerName
                      }
                      onBlur={formik.handleBlur}
                    />
                  )}
                </div>
              </div>

              {/* DATOS DE UBICACION */}
              {datoscontacto && (
                <div className="row">
                  <div className="col-lg-6">
                    <TextField
                      onChange={(e) => onChangeHandler(e.target.value)}
                      value={formik.values.PostalCode}
                      margin="normal"
                      variant="outlined"
                      id="PostalCode"
                      name="PostalCode"
                      label="CÓDIGO POSTAL"
                      fullWidth
                      disabled={isDisabledhospitaldata}
                      autoComplete="new-password"
                      error={
                        formik.touched.PostalCode &&
                        Boolean(formik.errors.PostalCode)
                      }
                      helperText={
                        formik.touched.PostalCode && formik.errors.PostalCode
                      }
                      onBlur={formik.handleBlur}
                    />
                    <ul className="suggestions-list">
                      {suggestions &&
                        suggestions.map((suggestion, i) => (
                          <li
                            className="col-lg-12 justify-content-md-center suggestion "
                            onClick={() => onSuggestHandler(suggestion)}
                            key={i}
                          >
                            {" "}
                            {suggestion.CP +
                              " " +
                              suggestion.ASENTAMIENTO +
                              " " +
                              suggestion.MUNICIPIO}
                          </li>
                        ))}
                    </ul>

                    <TextField
                      id="Region"
                      name="Region"
                      label="ESTADO"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formik.values.Region}
                      disabled={isDisabledhospitaldata}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Region && Boolean(formik.errors.Region)
                      }
                      helperText={formik.touched.Region && formik.errors.Region}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      id="AddressLine1"
                      name="AddressLine1"
                      label="CALLE Y NUMERO"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      disabled={isDisabledhospitaldata}
                      value={formik.values.AddressLine1.toUpperCase()}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.AddressLine1 &&
                        Boolean(formik.errors.AddressLine1)
                      }
                      helperText={
                        formik.touched.AddressLine1 &&
                        formik.errors.AddressLine1
                      }
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className="col-lg-6">
                    <TextField
                      id="Province"
                      name="Province"
                      label="DELEGACIÓN O MUNICIPÍO"
                      disabled={isDisabledhospitaldata}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formik.values.Province}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Province &&
                        Boolean(formik.errors.Province)
                      }
                      helperText={
                        formik.touched.Province && formik.errors.Province
                      }
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      id="District"
                      name="District"
                      label="ASENTAMIENTO O COLONIA"
                      disabled={isDisabledhospitaldata}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formik.values.District}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.District &&
                        Boolean(formik.errors.District)
                      }
                      helperText={
                        formik.touched.District && formik.errors.District
                      }
                      onBlur={formik.handleBlur}
                    />

                    <FormControl fullWidth margin="normal">
                      <InputLabel id="demo-simple-select-helper-label">
                        GENERO
                      </InputLabel>
                      <Select
                        id="Gender"
                        name="Gender"
                        label="GENERO"
                        disabled={isDisabledcedula}
                        value={formik.values.Gender}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.Gender && Boolean(formik.errors.Gender)
                        }
                        helperText={
                          formik.touched.Gender && formik.errors.Gender
                        }
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value={1}>HOMBRE</MenuItem>
                        <MenuItem value={2}>MUJER</MenuItem>
                      </Select>
                      <FormHelperText>
                          {formik.touched.Gender && formik.errors.Gender}
                        </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              )}

              <div className="row justify-content-center">
                {/*           <div className="col-lg-6">
                  <br></br>

                  
                  <Button
                    variant="contained"
               
                    fullWidth
                    className="boton-amarillo"
                    startIcon={<HomeIcon />}
                  >
                    <Link to="/" style={{ color: "black"  }}>
                    Regresar
                    </Link>
                  </Button>
                </div> */}
                <div className="col-lg-6">
                  <br></br>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    endIcon={<SendIcon />}
                    className="boton-amarillo"
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Encabezados table

export default RegistroAdministrativo;
