
import React, {Fragment} from 'react';
import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import './App.css'

import Login from './Components/Login'

import { BrowserRouter as Router, Route } from 'react-router-dom';


import ValidacionVistas from './Components/Vistas/ValidacionVistas';
import ValidacionAlta from './Components/Vistas/ValidacionAlta';
import Loading from './Components/Loading';
import RepresentanteMedico from './Components/Vistas/Representante-Medico';
import RegistroAdministrativo from './Components/Vistas/RegistroAdministrativo';
import Administrador from './Components/Vistas/Administrador';
import RegistroMedico from './Components/Vistas/RegistroMedico';
import ValidacionVistasFirst from './Components/Vistas/ValidacionVistasFirst';
import ExportCatalogo from './Components/Vistas/Exportcatalogo';
import VistaAdministrador from './Components/Vistas/VistaAdministrador';
import VistaRepresentante from './Components/Vistas/VistaRepresentante';
import Principal from './Components/Vistas/Principal';





const App = () => {

  return(
  <Fragment>
    <Router>
      <Route path="/ass" exact component={Login} />
      <Route path="/representante-medico" exact component={RepresentanteMedico} />
      <Route path="/administrador" exact component={Administrador} />
      <Route path="/" exact component={ValidacionVistasFirst} />
      <Route path="/registro-administrativo" exact component={RegistroAdministrativo} />
      <Route path="/registro-medico" exact component={RegistroMedico} />
      <Route path="/cargando" exact component={Loading} />
      <Route path="/export-catalogo" exact component={ExportCatalogo} />
     {/*  <Route path="/administradorv" exact component={VistaAdministrador} />
      <Route path="/representv" exact component={VistaRepresentante} /> */}
      


      
    </Router>
         
     <div className="container " >
       
        <div className="row  justify-content-center">

        <div className="col-md-6 "><br/><br/>
          {/* <Login  /> */} 
        </div>






    </div>
    </div>
  
  </Fragment>
  )


}



export default App;


// Todas las etiquetas deben cerrarse
// Los componentes deben devolver solo 1 elemento padre <padre> </padre> 
//apoyarse de los fragments cuando necesito devolver 2 padres o <> </>
// img siempre se cierra
//las clases de css se pone como ClassName
// el for en jsx es htmlFor

//no se utliza if else while

